<template>
	<!-- 门店分类 -->
	<div class="outlet-type el-content">
		<a-button class="mb12" v-has="{action:'wxapp_marketing_outlet_type_add'}" type="primary" @click="showAdd(0)">
			<i class="ri-add-line ri-lgri-top"></i>
			添加分店分类
		</a-button>
		<a-table :pagination="false" :data-source="list" row-key="id" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'分类名称',dataIndex:'name'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #action="{record}">
				<a-space>
					<kd-button 
						type="primary" 
						title="编辑" 
						icon="ri-more-fill" 
						@click="showAdd(record)" 
						v-has="{action:'wxapp_marketing_outlet_type_add'}">
					</kd-button>
					<kd-button 
						type="danger" 
						title="删除" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_marketing_outlet_type_del'}" 
						@click="deleteOutLet(record.id)">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<a-modal v-model:visible="show.add" title="添加分类" @ok="saveData" @cancel="show.add = false" width="500px">
			<a-input v-model:value="form.name" placeholder="分类名称" />
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import outletModel from '@/api/addons/outlet'
export default{
	setup(){
		const state = reactive({
			list:[],
			form:{
				id:0,
				name:'',
			},
			show:{ add:false, }
		})
		getOutletType()
		function getOutletType(){
			outletModel.getOutlet(res=>state.list = res)
		}

		function showAdd(row){
			state.form = {
				id:row ? row.id :0,
				name:row ? row.name :"",
			}
			state.show.add = true
		}

		const saveData = ()=>outletModel.addOrEditOutletClass(state.form,()=>{
			state.show.add = false
			getOutletType()
		})

		const deleteOutLet = (id)=>outletModel.deleteOutletClass(id,()=>getOutletType())

		return{
			...toRefs(state),
			getOutletType,
			saveData,
			showAdd,
			deleteOutLet
		}
	}
}
</script>

<style lang="scss">
</style>
